.editProfile {
  width: 100%;
  height: 100%;
  padding: 2rem 8rem;
  overflow-y: auto;

  @media screen and (max-width: 650px) {
    padding: 2rem 0rem;
  }

  .editProfileContainer {
    display: grid;
    padding: 2rem;
    justify-content: center;
    align-content: flex-start;
    gap: 1rem;

    .editProfileHeader {
      font-size: 2.5rem;
      text-transform: uppercase;
      grid-template-columns: min-content 1fr max-content;
      gap: 1rem;
      height: auto;

      .buttonLink {
        justify-self: end;
        align-self: flex-end;
        font-size: 1rem;
        .deleteButton {
          gap: 0.5rem;
        }
      }
    }

    .editProfileForm {
      display: grid;

      .formFields {
        display: grid;
        grid-template-columns: 0.75fr 1fr;
        gap: 1rem;
        align-items: center;

        .checkbox {
          place-items: center;
          height: 1rem;
        }

        .imagePreview {
          justify-self: center;
          height: auto;
        }

        .email {
          width: 100%;
        }
      }
    }
  }

  .editProfilePremiumIcon {
    color: var(--FontColor4);
  }
}
