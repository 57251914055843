.categoryPage {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  background: var(--BackGroundGradient);
  padding: 0;

  .heroBanner {
    grid-template-columns: 1fr;
    grid-template-rows: 10rem;
    height: 10rem;
    max-height: 10rem;
    overflow: hidden;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;

    .heroImage {
      grid-column: 1;
      grid-row: 1;
      min-width: 100%;
      justify-content: center;
      align-content: center;
      align-items: center;
      padding: 0;
      object-fit: cover;
    }
    .categoryTitle {
      grid-column: 1;
      grid-row: 1;
      width: 100%;
      font-size: 8rem;
      height: 10rem;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
  .categoryPageInner {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: min-content;
    padding: 4rem 10% 2rem 10%;

    .subtitle {
      grid-column: 1;
      font-size: 2.5rem;
      font-weight: 400;
      text-align: center;
    }

    .descriptionContainer {
      grid-column: 1;
      grid-row: 2;
      padding: 1rem;
      align-content: flex-start;
    }

    .description {
      font-size: 1.5rem;
      line-height: 1.3;
      text-indent: 4rem;
      align-items: flex-start;
      text-align: justify;
      row-gap: 2rem;
      width: 100%;
      white-space: break-spaces;

      p {
        margin: 0;
      }
    }

    .categorylists {
      grid-column: 2;
      grid-row: 1 / span 2;

      .videoData {
        grid-template-columns: min-content 1fr;
        .videoDate,
        .videoViews {
          font-size: 0.825rem;
        }

        @media screen and (max-width: 1368px) {
          .videoDate,
          .videoViews {
            font-size: 0.725rem;
          }
        }

        @media screen and (max-width: 1280px) {
          .videoDate,
          .videoViews {
            font-size: 1.5rem;
          }
        }

        @media screen and (max-width: 1024px) {
          .videoDate,
          .videoViews {
            font-size: 1.3rem;
          }
        }
      }
    }

    .timelineTitle {
      grid-column: 1 / -1;
      font-size: 2.5rem;
      padding: 1rem;
      width: 100%;
      text-align: left;
    }
    .timeline {
      grid-column: 1 / -1;
      display: flex;
      row-gap: 1rem;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .timelineEvent {
        padding: 1rem;
        background-color: var(--Color1);
        border-radius: 1rem;
        font-size: 2rem;
      }

      .timelineDivider {
        display: flex;
        font-size: 2rem;
        align-items: center;
        padding: 1rem;
      }
    }

    @media screen and (max-width: 1280px) {
      grid-template-columns: 1fr;
      padding: 4rem 5% 2rem 5%;

      .categorylists {
        grid-column: 1;
        grid-row: 3;
      }
    }
  }
}
