.base {
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr min-content;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  .baseContainer {
    overflow-y: scroll;
    max-height: 100%;

    .reloadSymbol {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      position: absolute;
      padding: 1rem;
      left: 50%;
      font-size: 3rem;
      z-index: 10;

      &.hide {
        display: none;
      }
    }
  }
}

.header {
  background-color: var(--BackGround1);
  z-index: 2;
  box-shadow: 0 0.1rem 0.6rem 0.1rem rgba(0, 0, 0, 0.5);

  .reloadSymbol {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    position: absolute;
    padding: 1rem;
    left: 50%;
    font-size: 3rem;
  }

  .bannerAnchor {
    height: 0;
    width: 0;
    top: 0;
    right: 0;
    position: absolute;
    z-index: 3;

    .showArrow {
      position: relative;
      top: 0.5rem;
      right: 2rem;
      width: 2rem;
      height: 1.5rem;
      justify-self: right;
      z-index: 2;

      cursor: pointer;

      &.hidden {
        display: none;
      }
      &:hover {
        @media (pointer: fine) {
          color: var(--Color1);
        }
      }

      @media screen and (max-width: 1024px) {
        height: 2.5rem;
      }
    }
  }
  .premiumBannerContainer {
    grid-template-columns: 3rem 1fr 3rem;
    background-color: var(--Color1);
    align-content: center;
    align-items: center;

    &.hidden {
      display: none;
    }

    .premiumBanner {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      grid-template-rows: 1fr;
      justify-content: center;
      justify-items: center;
      align-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 1.4rem;
      letter-spacing: 0.2rem;
      column-gap: 0.6rem;
      padding: 0.25rem;
      grid-column: 2;
    }
    a {
      display: inline;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        @media (pointer: fine) {
          color: var(--FontColor5);
        }
      }
    }

    &.isPremium {
      background-color: var(--BackGround2);
      color: var(--FontColor4);
      letter-spacing: unset;
      justify-content: flex-start;
      text-align: left;
      padding: 0 3.9rem;
    }

    &.loggingIn {
      background-color: var(--BackGround2);
      letter-spacing: unset;
      justify-content: flex-start;
      text-align: left;
      padding: 0 3.9rem;
    }

    .closeButton {
      font-size: 1.5rem;
      color: var(--FontColor1);
      cursor: pointer;
      &:hover {
        @media (pointer: fine) {
          color: var(--Color2);
        }
      }
    }
  }
}

.navMenuBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  width: 100dvw;
  height: 100vh;
  height: 100dvh;
  min-width: 100vw;
  min-width: 100dvw;
  min-height: 100vh;
  min-height: 100dvh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: -5;
}
.navBar {
  grid-auto-flow: column;
  grid-template-columns: min-content 1fr min-content 3rem;
  column-gap: 1rem;
  height: 4rem;
  align-content: center;
  align-items: center;
  background-color: var(--BackGround1);
  z-index: 2;
  padding-right: 1rem;

  @media screen and (max-width: 1024px) {
    height: 5rem;
    grid-template-columns: min-content 1fr auto 5rem;
    column-gap: 0;
  }

  .menu {
    height: 4rem;
    width: 4rem;
    padding: 0.5rem 1rem;
    grid-template-columns: 2rem;
    column-gap: 0.5rem;
    justify-content: center;
    align-content: center;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
      height: 5rem;
      width: 5rem;
    }

    .menuIcon {
      height: 2rem;
      width: 2rem;
      justify-content: center;
      align-content: center;
      align-items: center;
      text-align: center;
      @media screen and (max-width: 1024px) {
        height: 4rem;
        width: 4rem;
      }
    }

    .funkerLogo {
      height: 2rem;
      width: 5rem;
      contain: content;
      @media screen and (max-width: 1024px) {
        height: 4rem;
        width: 10rem;
      }
    }

    .navMenuWrapper {
      overflow: hidden;
      overflow: -moz-hidden-unscrollable;
      align-content: center;
      align-items: center;
      .navMenu {
        position: relative;
      }
    }

    .navMenuAnchor {
      .navMenu {
        bottom: -1rem;
        left: -1rem;

        @media screen and (max-width: 1024px) {
          bottom: -0.5rem;
          left: -1.5rem;
        }
        .navMenuAnchor {
          top: unset;
          &.submenu {
            .navMenu {
              bottom: unset;

              top: -1.3rem;
            }
          }
        }
        .navMenuItems {
          .navMenuItem {
            font-size: 1.4rem;
            @media screen and (max-width: 1024px) {
              font-size: 2.2rem;
            }
          }
        }
      }
    }
  }

  .logo {
    height: 2rem;
    width: 5rem;
    contain: content;
    overflow: hidden;
    overflow: -moz-hidden-unscrollable;
  }

  .links {
    grid-auto-flow: column;
    justify-content: flex-start;
    align-items: center;
    column-gap: 2rem;
    height: 4rem;

    @media screen and (max-width: 1024px) {
      height: 5rem;
      padding: 0 1rem;
    }
  }

  .searchContainer {
    grid-auto-flow: column;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 0.5rem;

    .searchIcon {
      font-size: 2rem;
      color: var(--FontColor3);
      cursor: pointer;
      @media screen and (max-width: 1024px) {
        font-size: 4rem;
      }
    }
    &.showBar {
      .search {
        height: 1.7rem;
        font-size: 1.2rem;
        width: 15rem;
        @media screen and (max-width: 1024px) {
          height: 3.5rem;
          font-size: 2.5rem;
          width: 100%;
        }
      }
    }
  }

  .avatar {
    height: 2.4rem;
    width: 2.4rem;
    cursor: pointer;
    @media screen and (max-width: 1024px) {
      height: 3.8rem;
      width: 3.8rem;
    }
  }
}

.pageLink {
  grid-template-rows: 3fr 1fr;
  font-weight: 400;
  height: 100%;
  max-height: 100%;
  font-size: 1.5rem;
  color: var(--FontColor1);
  align-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
  &.premium > a > .linkText {
    color: var(--FontColor4);
  }

  img {
    max-width: 5rem;
    @media screen and (max-width: 1024px) {
      max-width: 8rem;
    }
  }

  .underline {
    height: 0.2rem;
    min-height: 0.2rem;
    width: calc(100% - 0.05rem);
    align-self: flex-start;
  }

  &.active {
    .underline {
      background-color: var(--FontColor3);
      // @media screen and (max-width: 1024px) {
      //   display: none;
      // }
    }
    .linkText:has(img) + .underline {
      transform: translateY(0.2rem);
    }
  }

  &:hover {
    @media (pointer: fine) {
      color: var(--Color1);
    }
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }

  &.dontHide {
    @media screen and (max-width: 1024px) {
      display: grid;
      height: 5rem;
      max-height: 5rem;
      grid-template-rows: 4rem 0.5rem;
      align-content: center;
    }
  }
}

.navMenuAnchor {
  position: relative;
  max-height: 0;
  max-width: 0;
  z-index: 0;

  &.submenu {
    position: absolute;
    right: -0.95rem;

    .navMenu {
      left: 0;
      top: -0.5rem;
      .navMenuItems {
        border: solid 0.1rem black;
        border-radius: 0 0.5rem 0.5rem 0.5rem;
        box-shadow: unset;
        .navMenuItem {
          font-size: 1.2rem;
          text-transform: uppercase;
          @media screen and (max-width: 1024px) {
            font-size: 2rem;
          }
        }
      }
    }
  }

  .navMenu {
    position: relative;
    left: -0.5rem;
    width: max-content;
    .navMenuItems {
      box-shadow: inset 0 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
      border: solid 0.1rem black;
      border-top: 0;
      border-radius: 0 0 0.5rem 0.5rem;
      overflow: hidden;
      overflow: -moz-hidden-unscrollable;
      background-color: var(--BackGround1);
      .navMenuItem {
        color: var(--FontColor1);
        font-size: 1.2rem;
        padding: 0.4rem 0.5rem;
        cursor: pointer;
        &:hover {
          @media (pointer: fine) {
            background-color: var(--Color1);
          }
        }
        @media screen and (max-width: 1024px) {
          font-size: 2rem;
        }
      }
    }
  }
}

.footerContainer {
  position: relative;
  height: min-content;
  // overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  .footerTabAnchor {
    position: absolute;
    height: 0;
    width: 0;
    top: 0;
    right: 0;
    block-size: 0;
    .footerTab {
      position: relative;
      right: calc(100% + 0.9rem);
      bottom: 100%;
      height: 2rem;
      width: 3rem;
      border-bottom: 0;
      justify-content: center;
      align-items: center;
      background-color: var(--BackGround1);
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0)
      );
      justify-self: right;
      border-top-right-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
      z-index: 5;
      .tabButton {
        font-size: 1.5rem;
        cursor: pointer;
        &.hidden {
          display: none;
        }
        &:hover {
          @media (pointer: fine) {
            color: var(--Color1);
          }
        }
      }
    }
  }
  .footer {
    &.hidden {
      display: none;
    }

    grid-template-columns: 1fr max-content 1fr;
    padding: 0.5rem 3rem;
    width: 100%;
    height: min-content;
    background-color: var(--BackGround1);
    justify-content: space-between;
    z-index: 2;
    box-shadow: 0 -0.1rem 0.6rem 0.1rem rgba(0, 0, 0, 0.5);

    @media (max-width: 768px) {
      grid-template-rows: min-content min-content;
    }

    .copyright {
      font-size: 2rem;
      display: inline-block;
      justify-content: center;
      align-content: center;
      align-items: center;
      text-align: center;
      justify-self: flex-start;

      @media (max-width: 768px) {
        font-size: 2.5rem;
        justify-self: unset;
      }

      .red {
        color: var(--FontColor3);
      }
    }

    .footerMenu {
      justify-content: center;
      align-items: center;

      @media (max-width: 768px) {
        grid-column: 1/-1;
        grid-row: 2;
      }

      .navigation {
        display: flex;
        flex-wrap: wrap;
        height: min-content;
        justify-content: center;
        align-items: center;
        column-gap: 2rem;
        row-gap: 1rem;
        padding: 0.5rem 0;

        .pageLink {
          height: min-content;
          grid-template-rows: min-content min-content;
          font-size: 1.5rem;

          @media (max-width: 768px) {
            padding: 0.5rem 0;
          }
        }
      }
    }

    .socials {
      grid-template-columns: min-content min-content min-content;
      justify-content: center;
      align-items: center;
      column-gap: 1.5rem;
      justify-self: flex-end;

      @media (max-width: 768px) {
        grid-column: 3;
        grid-row: 1;
        justify-self: unset;
      }

      .linkIcon {
        color: var(--FontColor1);
        height: 2rem;
        width: 2rem;
        cursor: pointer;

        @media (max-width: 768px) {
          height: 2.5rem;
          width: 2.5rem;
          padding: 0.5rem 0;
        }
      }
    }
  }
}
