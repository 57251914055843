.goPremium {
  padding: 2rem;
  grid-template-rows: min-content min-content;
  row-gap: 2rem;
  text-align: center;
  justify-content: center;

  .goPremiumHero {
    justify-content: center;
    align-items: center;
    justify-items: center;
    height: auto;
    grid-template-rows: max-content max-content max-content min-content;
    row-gap: 0.75rem;
    min-width: 25vw;
    max-width: 90vw;
    justify-self: center;
    align-self: flex-end;
    padding: 2rem;

    .goPremiumLogo {
      width: 80%;
    }

    .goPremiumHeading {
      font-size: 2rem;
      font-weight: 700;
    }

    .goPremiumSubHeading {
      font-size: 1.5rem;
      font-weight: 400;
    }

    .goPremiumButton {
      font-size: 1.5rem;
      width: 100%;
    }

    @media screen and (max-width: 768px) {
      .goPremiumLogo {
        width: 70%;
      }
    }
  }

  .goPremiumPerks {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
    justify-content: center;
    align-items: center;
    justify-items: center;
    min-width: 60vw;
    justify-self: center;
    padding: 1rem;

    .goPremiumPerk {
      display: grid;
      grid-template-rows: max-content max-content;
      row-gap: 0.5rem;
      justify-items: center;
      align-items: center;
      justify-content: center;

      padding: 1rem;

      .goPremiumPerkIcon {
        font-size: 2.5rem;
        .premium {
          color: var(--FontColor4);
        }
      }

      .goPremiumPerkDescription {
        font-size: 2rem;
        font-weight: 400;
        text-align: center;
      }
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);
      .goPremiumPerk {
        padding: 0.5rem;
        row-gap: 0.5rem;
        column-gap: 0.5rem;
        grid-template-rows: 1fr;
        grid-template-columns: min-content max-content;
        justify-content: space-between;
      }
    }
  }
}
