.loginContainer,
.resetContainer {
  display: grid;
  justify-content: center;

  padding: 2rem;

  .loginBox {
    display: grid;
    justify-content: center;
    align-items: center;
    min-width: 25vw;
    min-width: 25dvw;
    max-width: 95vw;
    max-width: 95dvw;

    @media screen and (max-height: 630px) and (min-width: 1028px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-height: 575px) and (max-width: 1028px) {
      grid-template-columns: 1fr 1fr;
    }

    // height: minmax(95vh, 30vh);
    // height: minmax(95dvh, 30dvh);
    padding: 1rem;
    height: min-content;
    align-self: center;
    border: rgba(50, 50, 50, 1) 1px solid;

    .loginLogoContainer {
      width: 80%;
      height: auto;
      justify-self: center;
      grid-template-rows: 1fr min-content;
      padding: 0.5rem 0;

      .loginPageLogo {
        width: 100%;
        height: auto;
      }
    }

    .formContainer {
      width: 100%;
      justify-self: center;
      row-gap: 0.5em;

      .loginInputContainer {
        box-sizing: border-box;
        width: 100%;
        row-gap: 1rem;
        grid-template-columns: 1fr 1fr;
        padding: 1rem 0;
        display: flex;
        flex-direction: column;

        .loginInput {
          width: 100%;
          height: 2.5rem;
          padding: 0.5em;
          border: rgba(50, 50, 50, 1) 1px solid;
          border-radius: 0.5em;
          grid-column: 1 / -1;

          &.invalid {
            border: red 2px solid;
          }
        }

        .checkboxInput {
          grid-template-columns: min-content 1fr;
          column-gap: 0.5rem;
          width: 100%;
        }
      }

      .forgotPassword {
        font-style: italic;
        // text-decoration: none;
        color: white;
        font-size: 1.2rem;
        text-align: right;
      }

      .loginButton {
        font-size: 1.5rem;
      }
    }
    .authenticationError {
      color: var(--FontColor5);
      font-size: 1.5rem;
      padding-top: 1rem;
      min-height: 3rem;

      &.hidden {
        display: none;
      }
    }
    .confirmationMessage {
      font-size: 1.5rem;
      padding-top: 3rem;
      min-height: 3rem;

      a {
        color: var(--FontColor5);
      }
    }
  }

  .resetPageText {
    font-size: 1.5rem;

    a {
      color: var(--FontColor1);
    }
  }

  .react-tooltip {
    font-size: 1.5rem !important;
    .invalidField {
      color: red;
    }
  }
}
