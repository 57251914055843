* {
  box-sizing: border-box;
}

:root {
  font-size: 16px;
}
@media screen and (max-width: 1920px) {
  :root {
    font-size: 14px;
  }
}
@media screen and (max-width: 1280px) {
  :root {
    font-size: 12px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    font-size: 10px;
  }
}

body {
  margin: 0;
  background-color: var(--Color2);
  font-family: var(--siteFontFamily1);
  color: var(--FontColor1);
  letter-spacing: 0.15rem;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  width: 100dvw;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  grid-template-rows: min-content 1fr;

  .moreReadable {
    font-family: var(--siteFontFamily2);
    letter-spacing: 0.05rem;
  }
}

.errorCatch {
  height: 100%;
  width: 100%;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  text-align: center;

  &.mainError {
    font-size: 3rem;
    height: 100vh;
    height: 100dvh;
    width: 100vw;
    width: 100dvw;
    overflow-wrap: break-word;
    padding: 0 2rem;
  }
}

.base {
  color: var(--FontColor1);
  height: 100vh;
  height: 100dvh;
  max-height: 100dvh;
  width: 100vw;
  width: 100dvw;
  grid-template-rows: min-content 1fr;

  .mainContent {
    height: fit-content;
    width: 100%;
    overflow-x: hidden;
  }
}

div,
a {
  text-decoration: none;
  display: grid;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0.9rem;
    box-sizing: border-box;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--Color1);
    border: 0.15rem solid var(--Color2);
    border-radius: 0.2rem;
    border-right: 0;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--Color2);
  }
}

a {
  color: unset;
}
