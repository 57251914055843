.videoReel {
  grid-template-columns: 1fr 8fr 1fr;
  height: 30vw;
  height: 30dvw;
  background: var(--BackGroundGradient);

  @media screen and (max-width: 1024px) {
    grid-template-columns: 10rem 5fr 10rem;
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 0 1fr 0;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .playButtonSvg {
    grid-row: 1;
    grid-column: 1;
    z-index: 1;

    svg {
      height: 6rem;
      width: 6rem;
      align-self: center;
      justify-self: center;
      animation: spin 1s linear infinite;
    }
    &.spin {
      svg {
      }
    }
  }

  .mainVideo {
    padding: 1rem 1.5em 0 1.5rem;
    grid-template-columns: auto 1fr;
    height: 100%;
    min-height: 100%;
    column-gap: 1.5rem;
    overflow-y: hidden;
    max-width: 100%;

    .funkerPlayerContainer {
      grid-template-columns: 1fr;
      overflow: hidden;
      overflow: -moz-hidden-unscrollable;
      padding: 0.5rem 0 0 0;
      height: 100%;
      min-width: 52dvw;
      width: 100%;
      max-width: 100%;
      aspect-ratio: 16 / 9;
      grid-column: 1;
      grid-row: 1;

      .vjs-player,
      .innerPlayer {
        grid-template-columns: 1fr;
        aspect-ratio: 16/9;
        max-width: 100%;
      }

      iframe {
        position: unset !important;
        width: 100% !important;
        height: 100% !important;
      }
    }

    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr min-content;
      row-gap: 0;
    }

    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }

  .previewContainer {
    cursor: pointer;

    &.empty {
      opacity: 0;
      cursor: pointer;
    }

    .previewImage {
      width: 100%;
      height: 100%;
      position: relative;
      border-radius: 0.5rem;
      overflow: hidden;
      overflow: -moz-hidden-unscrollable;

      .previewImageOverlay {
        grid-column: 1;
        grid-row: 1;
        grid-template-rows: min-content min-content;
        align-self: flex-end;
        row-gap: 0.5rem;
        align-content: flex-end;
        padding: 0.5rem;
        font-family: var(--siteFontFamily2);
        font-size: 1.1rem;
        font-weight: 600;
        letter-spacing: 0.03rem;
        background-color: rgba(0, 0, 0, 0.7);
        color: var(--FontColor1);
        z-index: 1;
        overflow-wrap: break-word;

        .previewViewCount {
          grid-template-columns: min-content 1fr;
          grid-gap: 0.2rem;
          font-size: 0.8rem;
          align-items: center;
          align-content: center;

          svg {
            font-size: 0.9rem;
          }
        }
      }

      img {
        grid-column: 1;
        grid-row: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.3;
        transition: opacity 0.3s ease;

        &:hover {
          @media (pointer: fine) {
            opacity: 0.6;
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      width: 0;
      overflow: hidden;
      overflow: -moz-hidden-unscrollable;
    }
  }

  .verticalTextButton {
    writing-mode: vertical-rl;
    text-orientation: upright;
    padding: 0 0.1rem;
    font-size: 2em;
    text-align: center;
  }

  .previousVideo {
    grid-template-columns: 1fr;
    padding: 3rem 0.5rem 3rem 0;
    .previewImage {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .verticalTextButton {
      border-left: 2px solid var(--FontColor1);
    }
  }

  .nextVideo {
    grid-template-columns: 1fr;
    padding: 3rem 0 3rem 0.5rem;
    .previewImage {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .verticalTextButton {
      border-right: 2px solid var(--FontColor1);
    }
  }

  &.biggerVideo {
    grid-template-columns: calc(20vw - 0.2rem) 1fr calc(20vw - 0.2rem);
    grid-template-columns: calc(20dvw - 0.2rem) 1fr calc(20dvw - 0.2rem);
    grid-template-rows: 1fr min-content min-content;
    column-gap: 0.3rem;
    height: unset;
    padding-top: 0.5rem;

    .ad {
      grid-row: 2;
      height: max-content;

      @media screen and (max-width: 768px) {
        grid-row: 3;
      }
    }
    .previewContainer {
      grid-row: 1;

      &.previousVideo {
        padding: 0.5rem 0.5rem 0.5rem 0;
      }
      &.nextVideo {
        padding: 0.5rem 0 0.5rem 0.5rem;
      }
    }

    .mainVideo {
      padding: 0;
      grid-template-columns: 1fr;
      overflow: visible;
      border-radius: 0;
      grid-row: 1 / span 2;
      grid-column: 2;
      .funkerPlayerContainer {
        padding: 0;
        .vjs-player,
        .innerPlayer {
          border-radius: 0;
        }
      }
    }
    .videoInfo {
      padding: 2.5rem 6.5em 0 6.5rem;
      grid-column: 1 / span 3;
      grid-template-columns: 3fr 30rem;
      grid-template-rows: min-content min-content min-content 1fr;
      grid-auto-rows: min-content;

      &.premium {
        grid-template-rows: min-content min-content min-content 1fr;
      }

      @media screen and (max-width: 768px) {
        padding: 0.25rem 0.75rem 0 0.75rem;
      }

      .videoTitle {
        grid-column: 1;
        grid-row: 1 / span 2;
        grid-template-columns: 0 1fr;
        align-self: center;
        text-align: left;
        font-size: 4rem;
        cursor: unset;

        @media screen and (max-width: 1024px) {
          font-size: 3rem;
          padding: 0;
        }

        @media screen and (max-width: 768px) {
          grid-row: 2 / span 2;
        }
      }

      .ad {
        display: none;
        &.banner {
          height: fit-content;
          padding: 0 0 1rem 0;
          grid-column: 2;
          grid-row: 1;

          @media screen and (max-width: 768px) {
            grid-column: 1 / span 2;
            padding: 0;
            justify-self: center;
          }
        }
      }

      .authorInfo {
        grid-column: 2;
        grid-row: 1;
        justify-content: center;
        justify-self: center;
        align-content: center;
        align-items: center;
        text-align: center;
      }

      .videoIcons {
        grid-column: 2;
        grid-row: 2;
        column-gap: 1rem;
        justify-self: center;
        align-content: flex-start;
        min-height: 0;
      }

      .videoDescription {
        display: grid;
        grid-column: 1;
        grid-row: 3 / span 1;
        height: min-content;
        &.truncated {
          max-height: 15rem;
          overflow: hidden;
          overflow: -moz-hidden-unscrollable;
        }
      }

      .videoLists {
        padding-top: 1rem;
        grid-column: 2;
        grid-row: 3 / span 2;
      }

      #CommentSectionContainer {
        align-self: flex-start;
        align-content: flex-start;
        grid-column: 1;
        grid-row: 4;
        position: sticky;
        bottom: 0;
      }

      @media screen and (max-width: 1024px) {
        grid-template-rows: min-content min-content min-content min-content 1fr;
        .videoIcons {
          grid-column: 2;
          grid-row: 3;
          height: min-content;
          padding: 0.5rem 0 2rem 0;
        }

        .authorInfo {
          grid-column: 2;
          grid-row: 2;
          padding: 0.5rem 0;
        }

        .ad {
          &.banner {
            width: 100%;
            max-height: 100%;
            height: 100%;
          }
        }

        .videoLists {
          padding-top: 0;
          grid-row: 4 / span 2;
        }

        #CommentSectionContainer {
          align-self: flex-start;
          grid-row: 5;
        }
      }

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        // padding: 0;

        .videoTitle {
          grid-row: 2;
          grid-column: 1 / span 2;
        }

        .videoDescription {
          grid-row: 4;
          grid-column: 1 / span 2;
        }

        .authorInfo {
          grid-row: 3;
          grid-column: 1;
          justify-self: unset;
        }

        .videoIcons {
          grid-row: 3;
          grid-column: 2;
          justify-self: flex-end;
        }

        .videoLists {
          grid-column: 1 / span 2;
          grid-row: 5;
        }

        #CommentSectionContainer {
          grid-column: 1 / span 2;
          grid-row: 6;
        }
      }
    }

    @media screen and (max-width: 1280px) {
      grid-template-columns: 12vw 1fr 12vw;
      grid-template-columns: 12dvw 1fr 12dvw;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 0 1fr 0;
    }
  }
}
.videoInfo {
  padding: 0;
  grid-template-rows: min-content min-content 1fr min-content min-content;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content min-content min-content;
  }

  &.premium {
    .ad {
      display: none;
    }
  }

  .videoHeading {
    font-size: 2rem;
    color: var(--FontColor3);
    cursor: pointer;

    @media screen and (max-width: 1024px) {
      width: 15rem;
      height: 6rem;
      text-align: left;
      align-content: center;
    }
  }

  .videoTitle {
    font-size: 2.5rem;
    font-weight: 400;
    color: var(--FontColor1);
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
      width: 100%;
      grid-template-columns: 14rem 2fr;
      grid-template-rows: 1fr;
      grid-column: 1 / span 3;
      grid-row: 2;
      text-align: right;
      padding-bottom: 1rem;
      padding: 0 1rem;
      // height: 6rem;
    }
  }

  .authorInfo {
    grid-template-columns: 3rem 1fr;
    align-content: center;
    align-items: center;
    font-weight: 600;
    gap: 0.1rem;
    padding-bottom: 0.5rem;

    img {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      object-fit: cover;
      align-self: center;

      &.avatarImage {
        &.default {
          width: 100%;
          height: unset;
        }
      }
    }

    .authorName {
      text-transform: uppercase;
    }

    @media screen and (max-width: 1024px) {
      grid-column: 1;
      grid-row: 3;
      justify-self: flex-start;
      align-self: center;
      grid-template-columns: 5rem 1fr;
      padding: 0 1rem;

      img {
        width: 4rem;
        height: 4rem;
      }

      .authorName {
        font-size: 1.5rem;
      }
    }
  }

  .videoDescription {
    overflow-y: hidden;
    // max-height: 15rem;
    font-size: 1.1rem;
    font-family: var(--siteFontFamily2);

    @media screen and (max-width: 1024px) {
      grid-column: 1 / span 2;
      grid-row: 3;
      display: none;
      font-size: 1.4rem;
    }

    .description {
      grid-column: 1;
      grid-row: 1;
      div {
        display: block;
      }
      .aboutAuthor {
        display: grid;
        grid-template-columns: 8rem 1fr;
        column-gap: 1rem;
        width: 100%;
        padding: 1rem 0;
        grid-auto-rows: min-content;
        row-gap: 0.5rem;

        .aboutAuthorHeader {
          grid-column: 1 / -1;
          font-weight: 700;
        }
        .aboutAuthorName {
          grid-column: 2;
          align-content: center;
        }
        .aboutAuthorBody {
          grid-column: 1 / -1;
        }
      }

      p {
        margin-block-start: 0.2em;
        margin-block-end: 0.2em;
      }
      a {
        color: var(--FontColor5);
        text-decoration: none;
        font-weight: 900;
        &:hover {
          @media (pointer: fine) {
            text-decoration: underline;
          }
        }
      }
    }

    .readMore {
      align-self: flex-end;
      height: min-content;
      grid-template-rows: min-content 2rem;
      grid-column: 1;
      grid-row: 1;
      bottom: -0.1rem;
      position: sticky;

      .readMoreFader {
        position: relative;
        bottom: -0.1rem;
        background-image: linear-gradient(rgba(255, 255, 255, 0), black);
        height: 5.1rem;
        width: 100%;
      }

      .readMoreButton {
        height: 100%;
        font-weight: 600;
        background-color: var(--BackGround1);
        grid-auto-flow: column;
        justify-content: center;
        align-content: center;
        gap: 1rem;
        cursor: pointer;
      }
    }
  }

  .fullDescription {
    max-height: 100%;
  }

  .videoIcons {
    grid-template-columns: repeat(4, min-content) 1fr;
    gap: 2rem;
    min-height: 5rem;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    padding: 0 0.5rem;

    @media screen and (max-width: 1024px) {
      grid-column: 2;
      grid-row: 3;

      justify-self: flex-end;
      justify-content: flex-end;

      padding: 0 0 1rem 0;
      gap: 1rem;
      min-height: unset;
    }

    .iconContainer {
      // max-height: 2.5rem;
      grid-auto-flow: column;
      align-items: flex-end;

      &.clickable {
        cursor: pointer;
      }

      .iconCircle {
        display: grid;
        text-align: center;
        align-items: center;
        justify-content: center;
        justify-items: center;
        background-color: rgb(60, 60, 60);
        border-radius: 50%;
        border: 1px solid var(--FontColor1);
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1.8rem;
        color: var(--FontColor1);

        &.liked {
          background-color: var(--Color1);
        }
      }

      .relativeAnchor {
        position: relative;
        width: 0;
        max-width: 0;

        .iconStatCircle {
          position: relative;
          top: 50%;
          right: 50%;
          background-color: black;
          border-radius: 50%;
          width: 1.8rem;
          height: 1.8rem;
          font-size: 0.9rem;
          text-align: center;
          align-items: center;
          justify-content: center;
          display: grid;
        }
      }
    }
  }

  .ad {
    &.banner {
      justify-self: flex-start;
      width: 100%;
      padding-top: 0.3rem;
      overflow: hidden;
      overflow: -moz-hidden-unscrollable;

      @media screen and (max-width: 1024px) {
        grid-column: 1 / span 3;
        grid-row: 1;
        padding: 0;
        min-height: 0;

        justify-self: center;
      }
    }

    img {
      object-fit: contain;
      overflow: hidden;
      overflow: -moz-hidden-unscrollable;
    }
  }

  &.premium {
    @media screen and (max-width: 1024px) {
      grid-template-rows: min-content min-content;
      grid-template-columns: 14rem 1fr min-content;

      .videoTitle {
        grid-column: 1 / span 3;
        grid-row: 1;
        grid-template-columns: 14rem 2fr;
        grid-template-rows: 1fr;
        text-align: right;
      }
      .videoHeading {
        height: min-content;
        justify-self: flex-start;
      }
      .authorInfo {
        grid-column: 1;
        grid-row: 2;
        justify-self: center;
        align-self: center;
      }
    }
  }
}
