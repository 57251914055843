.funkerPlayer {
  height: 100%;
  width: 100%;
  display: contents;
  box-sizing: border-box;

  div {
    box-sizing: border-box;
  }

  &.testing {
    height: min(800px, 60vw);
    padding: 20px;
    display: grid;
  }

  .vjs-player {
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow: -moz-hidden-unscrollable;
    border-radius: 10px;
    background-size: cover;

    @media (max-width: 40em) {
      border-radius: 0;
    }

    .innerPlayer {
      display: grid;
      align-content: flex-start;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      height: 100%;
      width: 100%;
      max-height: 100%;
      max-width: 100%;
      overflow: hidden;
      overflow: -moz-hidden-unscrollable;

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      video-js {
        grid-column: 1;
        grid-row: 1;
        height: 100%;
        width: 100%;
        min-height: 100%;
        max-height: 100%;
        max-width: 100%;
        display: grid;
        &.vjs-seeking,
        &.vjs-waiting {
          .vjs-loading-spinner {
            display: none;
          }
          .vjs-big-play-button {
            display: block !important;
            .playButtonSvg {
              svg {
                animation: spin 1s linear infinite;
              }
            }
            .vjs-icon-placeholder {
              display: none;
            }
          }
        }

        &.vjs-layout-x-small {
          .vjs-control-bar {
            font-size: 1em;
            .vjs-progress-control {
              display: flex;
            }
            .vjs-remaining-time {
              display: flex;
            }
            .vjs-custom-control-spacer {
              display: none;
            }
            .vjs-playback-rate {
              display: flex;
            }
          }

          .unmuteButtonContainer {
            &.autoMuted {
              .unmuteButton {
                font-size: 3rem;
                height: 3rem;
                width: 3rem;
              }
            }
          }

          &.vjs-ad-playing {
            .unmuteButtonContainer {
              height: 5rem;
              width: 5rem;
            }
          }

          .vast-skip-button {
            font-size: 1.5em;
            bottom: 4em;
            width: 4.5em;
          }
        }
        &.vjs-layout-small {
          .vjs-control-bar {
            font-size: 1em;
            .vjs-progress-control {
              display: flex;
            }
            .vjs-remaining-time {
              display: flex;
            }
            .vjs-playback-rate {
              display: flex;
            }
          }

          .unmuteButtonContainer {
            &.autoMuted {
              .unmuteButton {
                font-size: 3rem;
                height: 3rem;
                width: 3rem;
              }
            }
          }

          &.vjs-ad-playing {
            .unmuteButtonContainer {
              height: 3rem;
              width: 3rem;
            }
          }

          .vast-skip-button {
            font-size: 1.5em;
            bottom: 4em;
            width: 4.5em;
          }
        }

        #vjs_video_3_html5_api {
          max-height: 100%;
        }

        .vjs-menu {
          margin-bottom: 0.5em;
          li {
            text-transform: none;
            user-select: none;
          }
          .vjs-menu-content {
            max-height: unset;

            @media screen and (max-width: 768px) {
              top: 1.5em;
              bottom: unset;
            }
          }

          @media screen and (max-width: 768px) {
            margin-bottom: unset;
            margin-top: 0.5em;
            top: 0;
          }
        }

        :focus-visible {
          text-shadow: none;
          outline: none;
        }

        .vjs-play-progress,
        .vjs-volume-level {
          background-color: rgb(206, 0, 0);
          color: rgb(206, 0, 0);
        }

        .vjs-big-play-button {
          display: grid;
          justify-content: center;
          align-content: center;
          text-align: center;
          border: 0;
          border-radius: 50%;
          color: rgba(125, 0, 0, 1);
          font-size: 4rem;
          height: 6rem;
          width: 6rem;
          margin-left: -3rem;
          margin-top: -3rem;
          z-index: 1;
          background: none;

          .vjs-icon-placeholder {
            text-align: center;
            display: grid;
            justify-content: center;
            align-content: center;
            line-height: 1.5;
            z-index: 1;

            &:hover {
              @media (pointer: fine) {
                color: rgba(206, 0, 0, 1);
              }
            }
          }
        }

        @media (max-width: 1024px) {
          .vjs-big-play-button {
            margin-top: -4.5rem;
            .playButtonSvg {
              svg {
                height: 10rem;
                width: 10rem;
                margin-left: -2rem;
                margin-top: -1.25rem;
              }
            }

            .vjs-icon-placeholder {
              font-size: 6rem;
              line-height: 1.25;
            }
          }
        }

        @media (max-width: 480px) {
          .vjs-big-play-button {
            margin-top: -4.5rem;
            .playButtonSvg {
              svg {
                height: 8rem;
                width: 8rem;
                margin-left: -1rem;
                margin-top: -0.25rem;
              }
            }

            .vjs-icon-placeholder {
              font-size: 6rem;
              line-height: 1.25;
            }
          }
        }

        .vjs-poster {
          background-color: #000;
        }

        &.vjs-big-play-centered {
          .vjs-icon-placeholder {
            display: none;
          }
          &.vjs-hls-quality-selector {
            .vjs-icon-placeholder {
              display: grid;
            }
            .playButtonSvg {
              svg {
                animation: none;
              }
            }
          }
        }

        &.vjs-ad-content-resuming,
        &.vjs-ad-loading,
        &.vjs-ad-content-playing,
        &.vjs-ad-playing,
        &.vjs-ad-content-paused,
        &.vjs-has-started {
          .vjs-big-play-button {
            display: none;
          }
        }

        &.vjs-ad-playing {
          .unmuteButtonContainer {
            width: 5rem;
            height: 5rem;
          }
        }

        &.vjs-paused {
          .vjs-big-play-button {
            display: block;
            z-index: 50;
          }
        }

        &.vjs-ended {
          .vjs-big-play-button {
            display: none;
          }
        }

        &.vjs-ad-playing {
          .vjs-big-play-button {
            display: none;
          }
        }

        .vjs-text-track-display {
          display: none;
        }

        &.vjs-has-started {
          .vjs-text-track-display {
            display: block;
          }
        }

        .vjs-time-tooltip {
          background-color: rgba(206, 0, 0, 0.9);
          color: #fff;
          font-size: 1em;
          line-height: 1em;
          padding: 5px;
          top: -2em;
        }

        .vjs-icon-placeholder {
          // line-height: 1.5;
          &:hover {
            @media (pointer: fine) {
              color: rgb(206, 0, 0);
            }
          }
        }

        .vjs-button {
          .vjs-icon-placeholder {
            line-height: unset;
            &:before {
              line-height: unset;
            }
          }
        }

        &.subtitlesDisabled {
          .vjs-subs-caps-button {
            .vjs-icon-placeholder {
              color: rgb(150, 150, 150);
            }
          }
        }

        .vjs-captions-button {
          display: none;
        }

        .vjs-control-bar {
          font-size: 1.5em;
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) 75%,
            rgba(0, 0, 0, 1) 100%
          );
          display: grid;
          grid-auto-flow: column;
          grid-template-rows: 2em 1fr 2em 3em;
          grid-template-columns: 3.5em 1fr 3.5em 3.5em 3.5em 3.5em 3.5em;
          height: 100%;
          pointer-events: none;

          @media screen and (max-width: 768px) {
            background: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0.5) 0%,
              rgba(0, 0, 0, 0) 12%,
              rgba(0, 0, 0, 0) 75%,
              rgba(0, 0, 0, 1) 100%
            );

            grid-template-columns: 3.5em 1fr 3.5em 3.5em 3.5em 3.5em 3.5em 3em;

            .vjs-remaining-time {
              grid-column-start: 8;
              padding: 0;
            }
          }

          > * {
            pointer-events: auto;
          }

          .showControlsButtonContainer {
            display: none;

            @media screen and (max-width: 768px) {
              display: flex;
              justify-content: center;
              align-content: flex-start;
              flex-wrap: wrap;
              grid-column: 8;

              .showControlsButton {
                color: #fff;
                border: 0;
                border-radius: 50%;
                font-size: 1.75em;
                display: grid;
                justify-content: center;
                align-content: center;
                position: absolute;
                cursor: pointer;
                &::before {
                  content: "\f114";
                  font-family: VideoJS;
                }
              }
            }
          }
          .vjs-control {
            grid-row: 3;

            @media screen and (max-width: 768px) {
              grid-row: 1;
              &.vjs-play-control {
                grid-row: 3;
              }
              &.vjs-volume-panel {
                grid-column: 1;
              }
              &.vjs-fullscreen-control {
                grid-row: 3;
                grid-column: 8;
              }
              &.vjs-playback-rate {
                grid-column: 4;
              }
              &.vjs-subs-caps-button {
                grid-column: 5;
              }
              &.vjs-quality-selector {
                grid-column: 6;
              }
              &.vjs-picture-in-picture-control {
                grid-column: 7;
              }
            }
          }

          .vjs-progress-control {
            grid-row: 4;
            grid-column-start: 1;
            grid-column-end: 7;
            width: 100%;

            @media screen and (max-width: 768px) {
              grid-column-start: 1;
              grid-column-end: 8;
            }
          }
          .vjs-remaining-time {
            grid-row: 4;
            grid-column-start: 7;
            grid-column-end: 8;
            width: 100%;
            grid-auto-flow: column;
            padding: 0;

            @media screen and (max-width: 768px) {
              grid-column-start: 8;
              grid-column-end: 9;
            }
          }
          .vjs-quality-selector {
            grid-column: 5;
          }
          .vjs-fullscreen-control {
            .vjs-icon-placeholder {
              &:before {
                font-size: 2.5em;
                line-height: 0.7;
              }
            }
          }

          .vjs-playback-rate-value {
            line-height: 1.4;
            font-size: 1.3em;
          }
          .vjs-playback-rate {
            .vjs-menu {
              color: #fff;
            }
            &:hover {
              @media (pointer: fine) {
                color: rgb(226, 0, 0);
              }
            }
          }
        }

        .recommendations {
          display: none;
        }

        .vast-skip-button {
          background-color: rgba(0, 0, 0, 0.7);
          width: 4em;
          text-align: center;
          bottom: 3em;
          padding: 0.4em;
          opacity: 0.5;
          font-size: 3em;

          &.enabled {
            opacity: 1;
          }

          &:hover {
            @media (pointer: fine) {
              background-color: rgba(50, 50, 50, 0.7);
            }
          }
        }

        .unmuteButtonContainer {
          display: none;

          &.autoMuted {
            display: grid;
            justify-content: flex-start;
            align-content: flex-start;
            z-index: 1;

            .unmuteButton {
              background-color: rgba(0, 0, 0, 0.7);
              color: #fff;
              border: 0;
              border-radius: 50%;
              font-size: 5rem;
              height: 5rem;
              width: 5rem;
              display: grid;
              justify-content: center;
              align-content: center;
              position: absolute;
              cursor: pointer;
              &::before {
                content: "\f104";
                font-family: VideoJS;
              }

              &:hover {
                @media (pointer: fine) {
                  background-color: rgba(50, 50, 50, 0.7);
                }
              }
            }
          }
        }

        &.vjs-ended {
          .vjs-control-bar {
            opacity: 1;
          }
          .recommendations {
            grid-column: 1;
            grid-row: 1;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-auto-rows: 1fr;
            grid-auto-columns: 0fr;
            grid-gap: 10px;
            padding: 10px;
            height: calc(100% - 8em);
            width: 100%;
            overflow: hidden;
            overflow: -moz-hidden-unscrollable;
            z-index: 1;
            align-self: baseline;

            .recommendationsItem {
              display: grid;
              justify-content: center;
              align-content: center;
              width: 100%;
              max-width: 100%;
              height: 100%;
              max-height: 100%;
              overflow: hidden;
              overflow: -moz-hidden-unscrollable;
              border: rgba(200, 200, 200, 0.7) 1px solid;
              border-radius: 0.6em;
              background-color: rgba(0, 0, 0, 0.7);
              grid-template-columns: 1fr;
              grid-template-rows: 1fr;
              text-decoration: none;
              cursor: pointer;

              &:nth-child(n + 5) {
                @media screen and (max-width: 768px) {
                  display: none;
                }
              }

              &:nth-child(n + 13) {
                display: none;
              }

              .recommendationsItemThumbnail {
                display: grid;
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                grid-column: 1;
                grid-row: 1;
                overflow: hidden;
                overflow: -moz-hidden-unscrollable;

                img {
                  width: 100%;
                  height: 100%;
                  max-width: 100%;
                  max-height: 100%;
                  object-fit: contain;
                  object-position: center;
                  overflow: hidden;
                  overflow: -moz-hidden-unscrollable;
                }
              }

              .recommendationsItemTitle {
                display: grid;
                height: 100%;
                width: 100%;
                max-width: 100%;
                max-height: 100%;
                color: #fff;
                font-size: 1.2em;
                font-weight: 600;
                grid-column: 1;
                grid-row: 1;
                text-align: left;
                background-color: rgba(0, 0, 0, 0.5);
                padding-top: 1em;
                padding-left: 0.5em;
                overflow: hidden;
                overflow: -moz-hidden-unscrollable;
                text-decoration: none;
              }
            }

            @media screen and (max-width: 768px) {
              grid-template-columns: 1fr 1fr;
              grid-template-rows: 1fr 1fr;
              height: calc(100% - 7em);
              padding-top: 2px;
            }

            @media screen and (max-width: 480px) {
              height: calc(100% - 6em);
            }
          }
        }

        &.vjs-ad-loading {
          .recommendations {
            display: none;
          }
        }
      }
    }
  }
}
