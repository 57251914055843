.deleteProfile {
  width: 100%;
  height: 100%;
  padding: 2rem;

  .deleteProfileContainer {
    height: fit-content;
    width: 100%;
    justify-self: center;
    align-self: center;
    justify-items: center;
    max-width: 25vw;
    gap: 1.5rem;

    @media screen and (max-width: 1920px) {
      max-width: 35vw;
    }

    @media screen and (max-width: 1440px) {
      max-width: 45vw;
    }

    @media screen and (max-width: 1024px) {
      max-width: 55vw;
    }

    @media screen and (max-width: 768px) {
      max-width: 75vw;
    }

    .deleteProfileWarning {
      justify-items: center;
      font-size: 2.5rem;
      gap: 1rem;
      text-align: center;
      .iconWarning {
        font-size: 4rem;
        color: var(--FontColor3);
      }
    }

    .deleteProfileConfirmation {
      gap: 1rem;

      .deleteProfileInput {
        width: 100%;
      }

      .deleteProfileButtons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;

        .disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}
