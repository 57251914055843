.userPage {
  padding: 2rem 8rem;
  grid-template-columns: 2fr min-content;
  grid-template-rows: min-content min-content min-content min-content 1fr;
  row-gap: 1rem;
  max-width: 100vw;

  &.showWarning {
    grid-template-rows: min-content min-content min-content min-content min-content 1fr;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .userProfileContainer {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 2rem;

    justify-items: center;
    align-items: center;
    height: fit-content;
    padding-bottom: 1rem;

    .userProfileAvatar {
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      margin: 0 auto;
      border: solid 0.25rem var(--BackGround1);
      overflow: hidden;
      overflow: -moz-hidden-unscrollable;
      place-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      grid-template-rows: max-content 1fr;
      align-content: center;
      justify-content: center;
      gap: 0.5rem;
      max-width: 100vw;
      .userProfileAvatar {
        max-width: 8rem;
        max-height: 8rem;
      }
    }

    .userProfileDetails {
      justify-self: flex-start;
      align-self: flex-start;
      font-size: 1.5rem;
      color: white;
      row-gap: 0.25em;

      .userProfileInfo {
        display: grid;
        grid-template-columns: min-content 1fr;
        gap: 0.5rem;
        align-items: center;

        .icon {
          font-size: 1.5rem;
        }

        &.name {
          font-size: 3rem;
          text-transform: uppercase;
        }
      }

      @media screen and (max-width: 768px) {
        justify-self: center;
        align-self: center;
        text-align: center;
        row-gap: 0.5em;
        .userProfileInfo {
          justify-items: center;
          gap: 0.25rem;
          &.name {
            font-size: 2rem;
          }
        }
      }
    }
  }

  .userStatsContainer {
    grid-column: 1/-1;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content;
    row-gap: 1rem;
    justify-items: flex-start;
    align-items: center;
    .userBioContainer {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: min-content 1fr;
      row-gap: 1rem;
      justify-items: flex-start;
      align-items: center;
      .userBioHeader {
        font-size: 2rem;
      }
      .userBioText {
        font-size: 1.5rem;
        text-align: justify;
      }
    }
  }

  #CommentSectionContainer {
    grid-column: 1 / -1;
    row-gap: 0.5rem;
    .newCommentForm {
      column-gap: 0.5rem;
      display: none;
    }
    .commentsCount {
      // display: none;
      font-family: var(--siteFontFamily1);
      letter-spacing: 0.15rem;
      font-weight: 400;
      font-size: 2.3rem;
      text-align: left;
      border-bottom: 0.15rem solid var(--FontColor1);
    }
  }

  .userControlsContainer {
    justify-items: center;
    align-items: center;

    .userControls {
      grid-template-columns: 15rem;
      grid-auto-flow: row;
      grid-auto-rows: 3rem;

      font-size: 1.5rem;
      text-transform: uppercase;
      row-gap: 1rem;
    }
  }
  .userButton {
    grid-template-columns: 2rem 1fr;
    gap: 1rem;
    justify-content: center;

    .icon {
      justify-self: center;

      &.premium {
        color: var(--FontColor4);
      }
    }
  }

  .userWarningContainer {
    place-self: center;
    place-items: center;
    padding: 1rem 3rem;
    grid-column: 1 / -1;
    font-size: 1.5rem;
    gap: 1rem;
    border: 1px solid var(--FontColor3);
    width: 75%;

    .userWarningHeader {
      text-align: center;
      place-items: center;
      color: var(--FontColor1);
      font-size: 2rem;
      .warningIcon {
        font-size: 3rem;
        color: var(--FontColor3);
      }
      .handshakeIcon {
        font-size: 3rem;
        color: var(--FontColor4);
      }
    }

    .underlined {
      text-decoration: underline;
    }

    @media screen and (max-width: 768px) {
      width: 95vw;
    }
  }

  .userFavoriteVideosSection {
    padding: 2rem 0;
    grid-column: 1 / -1;

    .videoList {
      padding: 0;
    }
  }
}
