.infoPage {
  font-family: var(--siteFontFamily2);
  justify-content: center;
  justify-self: center;
  text-align: center;
  height: 100%;
  max-width: 75rem;
  padding: 1rem 2rem;

  h2,
  h3,
  h4 {
    padding-bottom: 1rem;
    text-align: left;
    text-decoration: underline;
    a {
      font-size: inherit;
    }
  }

  &.about {
    h2 {
      text-decoration: none;
    }
  }

  .ourTeam {
    .ourMission {
      h2,
      h3 {
        text-align: center;
        padding-bottom: 1rem;
        margin: 0;
        text-decoration: none;
      }
    }

    .ourMissionLogo {
      width: 100%;
      height: auto;
      max-width: 25rem;
      margin: 0 auto;
      padding: 1rem 0 2rem 0;
    }

    .teamMembers {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
      gap: 1rem;
      justify-content: center;
      padding: 1rem 0;

      .teamMember.card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        padding: 1rem;

        .teamAvatar {
          height: auto;
          width: 75%;
          contain: content;
          max-height: 100%;
          max-width: 100%;
          aspect-ratio: 1/1;
          padding: 1.5rem;

          img {
            height: 100%;
            width: 100%;
            contain: content;
            border-radius: 50%;
            object-fit: cover;
            border: solid 0.25rem var(--Color1);
          }

          @media screen and (max-width: 458px) {
            width: 50%;
          }

          @media screen and (max-width: 320px) {
            width: 75%;
          }
        }
        h2,
        h3 {
          margin: 0;
          padding-bottom: 0.5rem;
          text-decoration: none;
        }
      }
    }
  }

  a {
    display: inline;
    color: var(--FontColor3);
    font-size: 1.2rem;
  }
  p {
    padding-bottom: 1rem;
    line-height: 1.6rem;
    text-align: left;
  }
  li {
    text-align: left;
    padding-bottom: 0.5rem;
  }
  .infoPageContainer {
    height: min-content;
  }
}
