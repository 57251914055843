body {
  --funkerCommentsFontColor: rgba(0, 0, 0, 0.87);
  --funkerCommentsFontColor2: rgba(0, 0, 0, 0.4);
  --funkerCommentsProIconColor: rgba(255, 215, 0, 1);
  --funkerCommentsProIconColor2: rgba(255, 215, 0, 0.6);
  --funkerCommentsBorderColor: rgba(34, 36, 38, 0.15);
  --funkerCommentsCommentBackgroundColor: #ffffff;
  --funkerCommentsTextEntryBackgroundColor: #f5f5f5;
  --funkerCommentsNewCommentButtonBackgroundColor: rgb(66, 103, 178);
  --funkerCommentsNewCommentButtonTextColor: #ffffff;
  --funkerCommentsBoxShadowColor: rgba(149, 157, 165, 0.3);
  --funkerCommentsDimmedColor: rgba(0, 0, 0, 0.4);
  --funkerCommentsHighlight1BackgroundColor: lightgrey;
  --funkerCommentsHighlight2BackgroundColor: palevioletred;
  --funkerCommentsHighlight3BackgroundColor: lightskyblue;
  --funkerCommentsHighlight4BackgroundColor: #bdaeeb;
  --funkerCommentsCommentAwardPinnedColor: #161deb;
  --funkerCommentsCommentAwardTopCommentColor: #8863f7;
  --funkerCommentsCommentAwardJokerColor: #f7a338;
  --funkerCommentsCommentAwardMasterDebatorColor: #ebba18;
  --funkerCommentsUpvoteColor: #007049;
  --funkerCommentsDownvoteColor: #d64600;
  --funkerCommentsUpvoteHoverColor: #00be7b;
  --funkerCommentsDownvoteHoverColor: #ff4d4f;
  --funkerCommentsAdminActionColor: #ff0000;
}

[data-funkertheme="dark"] {
  --funkerCommentsFontColor: rgba(255, 255, 255, 0.87);
  --funkerCommentsFontColor2: rgba(255, 255, 255, 0.3);
  --funkerCommentsBorderColor: rgba(255, 255, 255, 0.4);
  --funkerCommentsCommentBackgroundColor: #000000;
  --funkerCommentsTextEntryBackgroundColor: #101010;
  --funkerCommentsNewCommentButtonBackgroundColor: rgb(17, 42, 93);
  --funkerCommentsBoxShadowColor: rgba(40, 40, 40, 0.3);
  --funkerCommentsDimmedColor: rgba(255, 255, 255, 0.3);
  --funkerCommentsHighlight1BackgroundColor: rgb(55, 55, 55);
  --funkerCommentsHighlight2BackgroundColor: rgb(54, 26, 35);
  --funkerCommentsHighlight3BackgroundColor: rgb(34, 55, 68);
  --funkerCommentsHighlight4BackgroundColor: #342c4b;
}

#CommentSectionContainer {
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  font-size: 1rem;
  color: var(--funkerCommentsFontColor);
  font-family: "roboto", sans-serif;
  font-weight: 400;
  caret-color: var(--funkerCommentsFontColor);
  padding-top: 0.5rem;

  #commentTooltip {
    z-index: 1;
  }

  div,
  input,
  textarea {
    box-sizing: border-box;
  }

  input,
  textarea {
    caret-color: var(--funkerCommentsFontColor);
    color: var(--funkerCommentsFontColor);

    &::placeholder {
      color: var(--funkerCommentsFontColor);
      opacity: 0.6;
    }
  }

  .commentEditForm {
    padding: 0.5rem 0;

    .commentEditFormInput {
      height: 7rem;
      display: flex;
      flex-direction: column;
      flex: 1 1 0px;
      resize: none;
      border: 0.1rem solid var(--funkerCommentsBorderColor);
      outline: 0;
      border-radius: 0.25rem;
      vertical-align: top;
      font-family: Arial, Helvetica, sans-serif;
      background-color: transparent;
    }

    .commentEditPostButton {
      width: max-content;
      padding: 1rem;
      display: grid;
      grid-template-columns: max-content max-content;
      color: #ffffff;
      align-items: center;
      border-radius: 0.25rem;
      cursor: pointer;

      .commentEditPostButtonIcon {
        width: 1.5em;
        text-align: center;
      }

      .commentEditPostButtonText {
        width: 8rem;
        white-space: pre-line;
        text-align: center;
        font-weight: 700;
      }
    }
  }

  div {
    box-sizing: border-box;
  }

  svg {
    &:focus {
      text-shadow: none;
      outline: none;
    }
  }

  .commentProIcon {
    color: var(--funkerCommentsProIconColor);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2rem;
    cursor: pointer;
  }

  .newCommentForm {
    display: flex;
    flex: 1 1 0px;
    grid-gap: 0.5rem;
    padding: 0 0 1rem 0;
    column-gap: 0.5rem;

    #newParentCommentText {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      flex: 1 1 0px;
      height: 5rem;
      font-size: 1rem;
      resize: none;
      padding: 0.78571429em 1em;
      background: transparent;
      border: 0.1rem solid var(--funkerCommentsBorderColor);
      outline: 0;
      border-radius: 0.25rem;
    }
    .newCommentPostButton {
      width: max-content;
      height: 4rem;
      padding: 1rem;
      display: grid;
      grid-template-columns: max-content max-content;
      column-gap: 0.5rem;
      background-color: var(--funkerCommentsNewCommentButtonBackgroundColor);
      color: var(--funkerCommentsNewCommentButtonTextColor);
      align-items: center;
      border-radius: 0.25rem;
      cursor: pointer;

      &.postButtonDisabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      .newCommentPostButtonIcon {
        text-align: center;
      }

      .newCommentPostButtonText {
        width: max-content;
        white-space: normal;
        text-align: center;
        font-weight: 400;
        font-size: 1.2rem;
      }
    }
  }

  .betaNotice {
    .betaNoticeTitle {
      font-size: 1.4rem;
      font-weight: 700;
    }
    display: flex;
    flex-direction: column;
    flex: 1 1 0px;
    text-align: center;
    padding: 0.5rem;
    border-radius: 1rem;
    font-size: 1.1rem;
    background-color: var(--Color1);
  }

  .commentsCount {
    display: flex;
    flex-direction: column;
    flex: 1 1 0px;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 700;
    border-bottom: 0.1rem solid var(--funkerCommentsBorderColor);
    padding: 0.5rem 0;
  }

  .commentsList {
    display: flex;
    flex-direction: column;
    flex: 1 1 0px;

    .commentContainer {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1 1 0px;

      svg {
        width: 1rem;
        height: 1rem;
        @media screen and (max-width: 1024px) {
          width: 1.4rem;
          height: 1.4rem;
        }
      }

      .commentContentContainer {
        height: fit-content;
        display: grid;
        grid-template-columns: min-content auto;
        padding: 0.5rem 0;
        column-gap: 0.5rem;
        grid-column: 1;
        grid-row: 1;

        &.highlight1 {
          background-color: var(--funkerCommentsHighlight1BackgroundColor);
        }
        &.highlight2 {
          background-color: var(--funkerCommentsHighlight2BackgroundColor);
        }
        &.highlight3 {
          background-color: var(--funkerCommentsHighlight3BackgroundColor);
        }
        &.highlight4 {
          background-color: var(--funkerCommentsHighlight4BackgroundColor);
        }

        .commentAvatar {
          box-sizing: border-box;
          cursor: pointer;
          align-self: flex-start;

          &.commentProUser {
            img {
              border-color: var(--funkerCommentsProIconColor);
            }
          }

          img {
            height: fit-content;
            height: 3.5rem;
            width: fit-content;
            width: 3.5rem;
            object-fit: cover;
            border-radius: 50%;
            border-width: 0.2rem;
            border-style: solid;
            border-color: var(--Color2);
            padding: 0.1rem;
            box-sizing: content-box;
          }
        }

        .commentBody {
          display: flex;
          flex-direction: column;
          flex: 1 1 0px;
          // height: fit-content;
          // display: grid;
          // grid-template-rows: min-content 1fr min-content;
          row-gap: 0.5rem;
          justify-content: center;
          padding-bottom: 2rem;

          .commentHeader {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 0.25rem;

            .commentAuthor {
              font-size: 1rem;
              font-weight: 700;
              cursor: pointer;
              text-transform: uppercase;
              @media screen and (max-width: 1024px) {
                font-size: 1.4rem;
              }
            }
            .commentProText {
              max-height: 1rem;
              align-content: center;
            }

            .commentMetadata {
              display: flex;
              flex-wrap: wrap;
              grid-gap: 0.5rem;

              .commentIcon {
                &.pointer {
                  cursor: pointer;
                }
              }

              .commentDate {
                color: var(--funkerCommentsFontColor2);
                @media screen and (max-width: 1024px) {
                  font-size: 1.2rem;
                }
              }

              .commentAwardPinned {
                color: var(--funkerCommentsCommentAwardPinnedColor);
              }

              .commentAwardTopComment {
                color: var(--funkerCommentsCommentAwardTopCommentColor);
              }

              .commentAwardJoker {
                color: var(--funkerCommentsCommentAwardJokerColor);
              }

              .commentAwardMasterDebater {
                color: var(--funkerCommentsCommentAwardMasterDebaterColor);
              }

              .commentAwardFaded {
                opacity: 0.4;

                &:hover {
                  @media (pointer: fine) {
                    opacity: unset;
                  }
                }
              }

              .commentAwardHidden {
                display: none;
              }
            }
          }

          .commentText {
            display: flex;
            flex: 1 1 0px;
            // height: fit-content;
            // min-height: 1.2rem;
            text-align: left;
            word-wrap: break-word;
            overflow-wrap: anywhere;
            font-size: 1rem;
            @media screen and (max-width: 1024px) {
              font-size: 1.4rem;
            }
          }

          .commentActions {
            display: flex;
            flex-wrap: wrap;
            flex: 1 1 0px;
            grid-gap: 0.5rem;
            font-weight: 540;

            .commentReply {
              display: grid;
              opacity: 60%;
              order: 2;
              cursor: pointer;
              &:hover {
                @media (pointer: fine) {
                  opacity: 1;
                }
              }
              @media screen and (max-width: 1024px) {
                font-size: 1.4rem;
              }
            }
            .newCommentReplyForm {
              display: flex;
              flex: 1 1 0px;
              grid-row: 2;
              grid-column: span 4;
              row-gap: 1rem;
              column-gap: 0.5rem;
              order: 5;

              .newCommentReplyFormInput {
                box-sizing: border-box;
                height: 7rem;
                display: flex;
                flex: 1 1 0px;
                resize: none;
                background: transparent;
                border: 0.1rem solid var(--funkerCommentsBorderColor);
                outline: 0;
                border-radius: 0.25rem;
                vertical-align: top;
                font-family: Arial, Helvetica, sans-serif;
              }

              .newCommentPostButton {
                width: max-content;
                height: 4rem;
                padding: 1rem;
                display: grid;
                grid-template-columns: max-content max-content;
                column-gap: 0.5rem;
                background-color: var(
                  --funkerCommentsNewCommentButtonBackgroundColor
                );
                color: var(--funkerCommentsNewCommentButtonTextColor);
                align-items: center;
                border-radius: 0.25rem;
                cursor: pointer;

                &.postButtonDisabled {
                  opacity: 0.5;
                  cursor: not-allowed;
                }

                .newCommentPostButtonIcon {
                  text-align: center;
                }

                .newCommentPostButtonText {
                  white-space: pre-line;
                  text-align: center;
                  font-weight: 400;
                }
              }
            }

            .commentEdit {
              display: grid;
              order: 3;
              cursor: pointer;
              opacity: 60%;
              &:hover {
                @media (pointer: fine) {
                  opacity: 1;
                }
              }
            }

            .commentVoting {
              display: grid;
              order: 1;
              grid-template-columns: max-content max-content max-content;
              grid-gap: 0.5rem;
              opacity: 60%;
              font-weight: 600;
              cursor: pointer;
              @media screen and (max-width: 1024px) {
                font-size: 1.2rem;
              }

              .commentUpvote {
                &:hover {
                  @media (pointer: fine) {
                    color: var(--funkerCommentsUpvoteHoverColor);
                    opacity: 1;
                  }
                }
              }

              .commentUpvoteActive {
                color: var(--funkerCommentsUpvoteColor);
                opacity: 1;
              }

              .commentDownvote {
                &:hover {
                  @media (pointer: fine) {
                    color: var(--funkerCommentsDownvoteColor);
                    opacity: 1;
                  }
                }
              }

              .commentDownvoteActive {
                color: var(--funkerCommentsDownvoteColor);
                opacity: 1;
              }
            }

            .commentAdminActions {
              display: grid;
              order: 4;
              grid-template-columns: max-content max-content max-content max-content;
              grid-gap: 0.5rem;
              opacity: 60%;
              cursor: pointer;

              .commentIcon {
                &:hover {
                  @media (pointer: fine) {
                    color: var(--funkerCommentsAdminActionColor);
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }

      .commentChildren {
        display: flex;
        flex: 1 1 0px;
        min-height: 2.5rem;
        padding-left: 1.5rem;

        &.minimized {
          grid-template-columns: 1fr 0;

          .commentMinimizeChildren {
            display: flex;
            flex-direction: column;
            flex: 1 1 0px;
            .commentMinimizeChildrenExpand {
              align-items: center;
            }
          }
        }

        .commentMinimizeChildren {
          width: 1.5rem;
          min-width: 1.5rem;
          // height: 100%;
          font-size: 1rem;
          font-weight: 600;
          text-align: left;
          cursor: default;
          user-select: none;
          padding-left: 0.2rem;
          // border-top: solid rgba(255, 255, 255, 0.25) 0.1rem;
          border-left: solid rgba(255, 255, 255, 0.4) 0.1rem;
          // border-bottom: none;
          &.parentDimmed {
            background-color: var(--funkerCommentsDimmedColor);
          }
          box-sizing: border-box;

          .commentMinimizeChildrenExpand {
            display: grid;
            column-gap: 1rem;
            grid-template-columns: min-content auto;
            column-gap: 0.7rem;
            // height: 100%;

            .commentMinimizeChildrenExpandText {
              display: flex;
              align-items: center;
              text-align: center;
              font-size: 1rem;
              height: 2rem;
            }
          }
        }

        .commentChildList {
          width: 100%;
        }
      }

      .commentOverlay {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        flex: 1 1 0px;
        // min-height: 100%;
        // width: 100%;
        grid-column: 1/-1;
        grid-row: 1;
        cursor: pointer;
        z-index: 1;

        &:hover {
          @media (pointer: fine) {
            background-color: rgba(255, 255, 255, 0.05);
          }
        }
      }
    }
  }
}
// @keyframes rotateBackgroundGradient {
//   0% {
//     background-position: top left;
//   }
//   50% {
//     background-position: center;
//   }
//   100% {
//     background-position: top left;
//   }
// }
