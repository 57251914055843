.videoList {
  padding: 0 1rem 1rem 1rem;
  grid-template-rows: min-content min-content;
  height: fit-content;

  @media screen and (max-width: 768px) {
    padding: 0 0 1rem 0;
  }
  .videoListTitleSection {
    grid-template-columns: 1fr min-content;
    border-bottom: solid 0.15rem var(--Color1);
    padding: 0.2rem;

    .videoListTitle {
      font-size: 2rem;
      font-weight: 400;
      width: fit-content;

      @media screen and (max-width: 768px) {
        font-size: 2.5rem;
        padding-top: 1rem;
      }
    }
    .videoListMore {
      font-size: 1.2rem;
      align-items: flex-end;
      cursor: pointer;
    }
  }
  .videoListInner {
    grid-template-columns: min-content min-content 1fr min-content min-content;
    padding: 1rem 0;

    &.singleRow {
      grid-template-columns: min-content 1fr min-content;
    }

    .videoListArrow {
      align-items: center;
      font-size: 2.8rem;
      opacity: 0.7;
      cursor: pointer;

      &.inactive {
        opacity: 0.4;
        cursor: default;

        &:hover {
          @media (pointer: fine) {
            opacity: 0.4;
          }
        }
      }

      &:hover {
        @media (pointer: fine) {
          opacity: 1;
        }
      }

      &.noArrow {
        max-width: 0;
        max-height: 0;

        svg {
          display: none;
        }
      }
    }

    .videoListGrid {
      grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
      grid-auto-rows: 1fr;
      padding: 0;

      &.container {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
      }

      &.singleRow {
        padding-left: 0;
        padding-right: 0;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: min-content;
        overflow-x: hidden;
        grid-gap: 0;
        width: 300%;
        justify-self: center;

        &.container {
          grid-auto-columns: unset;
          grid-template-columns: unset;
          width: 100%;
          grid-gap: 0;

          @media screen and (max-width: 768px) {
            grid-template-columns: 1fr !important;
            grid-template-rows: max-content 1fr;
            width: 100%;
          }
        }

        .listSection {
          width: 100%;
          background-color: var(--Color2);
          overflow-x: hidden;
          min-height: 2rem;
          grid-row: 1;
          grid-gap: 1rem;
          padding: 0 0.5rem;

          .videoListing {
            background-color: var(--Color2);
            width: 100%;
            padding: 0;
          }
          grid-auto-flow: column;

          &.inactive {
            opacity: 0;
          }
          &.main {
            grid-column: 2;
            &:hover {
              @media (pointer: fine) {
                opacity: 0.9;
              }
            }
          }
          &.previous {
            grid-column: 1;
          }
          &.next {
            grid-column: 3;
          }

          &.right {
            pointer-events: none;

            .videoListing {
              background-color: var(--Color2);
            }
          }
          &.left {
            pointer-events: none;
            .videoListing {
              background-color: var(--Color2);
            }
          }
        }
      }

      &.beginning {
        padding: 1rem 0rem 0 0rem;
        &.active {
          padding: 1rem 1.5rem 0 0.5rem;
        }
      }

      &.ending {
        &.active {
        }
      }

      @media screen and (max-width: 768px) {
        // grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        padding: 0 0.5rem;
      }

      @media screen and (max-width: 500px) {
        // grid-template-columns: 1fr;
        grid-gap: 1rem;
        padding: 0 0.5rem;
      }
    }
    .videoListing {
      display: flex;
      flex-direction: column;
      // grid-template-rows: min-content 4.75rem min-content;
      flex: 1 1 0px;
      // aspect-ratio: 1/1;
      padding: 0.5rem;
      contain: content;
      height: min-content;
      cursor: pointer;
      overflow: hidden;
      overflow: -moz-hidden-unscrollable;

      @media screen and (max-height: 350px) {
        width: 15dvw;
      }

      &.loading {
        width: 20%;
        position: fixed;
        right: 40%;
        bottom: 40%;
      }

      &.hidden {
        display: none;
      }
      &.listAd {
        display: flex;
        flex: 1 1 0px;
        contain: content;
        overflow-x: hidden;

        .box {
          display: flex;
          flex: 1 1 0px;
          justify-content: center;

          img {
            aspect-ratio: 1/1;
            width: 90%;
            contain: strict;
          }
        }

        .banner {
          padding: 0 0.5rem;
          object-fit: fill;
          aspect-ratio: 213 / 26;
          img {
            height: unset;
          }
        }

        &.outer {
          z-index: 1;
          background-color: var(--Color2);
          padding: 0.5rem 0;
          width: 100%;

          &:hover {
            @media (pointer: fine) {
              opacity: 1;
            }
          }
        }

        &.right {
          animation: unset;
        }
        &.left {
          animation: unset;
        }
      }

      &:hover {
        // background-color: rgba(255, 255, 255, 0.1);
        // outline: solid 0.3rem rgba(255, 255, 255, 0.1);
        @media (pointer: fine) {
          opacity: 0.9;
        }
      }

      .videoThumbnail {
        width: 100%;
        height: 56.25%;
        contain: content;
        aspect-ratio: 16/9;
        border-radius: 0.4rem;
        img {
          width: 100%;
          max-width: 100%;
          contain: strict;
          height: 100%;

          &.loading {
            width: 100%;
          }
        }
      }
      .listVideoTitle {
        display: inline-flex;
        height: calc(43.75% - 1.5rem);
        height: 2.5lh;
        font-size: 1.2rem;
        font-family: var(--siteFontFamily2);
        font-weight: 600;
        letter-spacing: 0.03rem;
        overflow: hidden;
        overflow: -moz-hidden-unscrollable;
        align-self: flex-start;
        padding-top: 0.5rem;
        overflow-wrap: break-word;
        white-space: pre-wrap;

        &.loading {
          text-align: center;
          font-size: 2em;
        }

        @media screen and (max-width: 1280px) {
          font-size: 1.5rem;
          height: 2.3lh;
        }

        @media screen and (max-width: 1024px) {
          font-size: 2rem;
        }

        @media screen and (max-width: 853px) {
          font-size: 1.4rem;
        }

        @media screen and (max-width: 364px) {
          font-size: 2rem;
        }
      }
      .videoData {
        grid-template-columns: min-content 1fr;
        height: 2.5rem;
        opacity: 0.7;
        padding-top: 0.5rem;
        font-family: var(--siteFontFamily2);
        font-size: 1rem;
        letter-spacing: 0.1rem;

        &.loading {
          display: none !important;
        }
        .videoViews {
          max-width: 100%;
          grid-template-columns: min-content 1fr;
          grid-gap: 0.2rem;
        }
        .videoDate {
          text-align: right;
          max-width: 100%;
        }
        .videoDate,
        .videoViews {
          @media screen and (max-width: 1920px) {
            font-size: 1.15rem;
          }

          @media screen and (max-width: 1280px) {
            font-size: 1.3rem;
          }

          @media screen and (max-width: 1024px) {
            font-size: 1.15rem;
          }

          @media screen and (max-width: 658px) {
            font-size: 1.35rem;
          }
        }
      }

      &.biggerThumbnail {
        // grid-template-rows: 14rem 4.5rem 2rem;
        // font-size: 1.1rem;
        // max-width: unset;

        @media screen and (max-width: 768px) {
          // .videoThumbnail {
          //   width: 100%;
          //   aspect-ratio: 16/9;
          // }

          // .listVideoTitle {
          //   align-self: flex-start;
          //   font-size: 1.3rem;
          //   @media screen and (max-width: 500px) {
          //     font-size: 1.5rem;
          //   }
          // }
          // .videoData {
          //   font-size: 1.3rem;
          //   @media screen and (max-width: 500px) {
          //     font-size: 1.5rem;
          //   }
          // }
          // .videoListGrid {
          //   grid-template-columns: 1fr 1fr;
          //   grid-gap: 1rem;
          //   padding: 0 0.5rem;
          // }
        }

        @media screen and (max-width: 500px) {
          // .videoListGrid {
          //   grid-template-columns: 1fr;
          //   grid-gap: 1rem;
          //   padding: 0 0.5rem;
          // }
        }

        &.listAd {
          // max-height: 100%;
          .box {
            width: 95%;
          }
        }

        &.hidden {
          display: none;
        }
      }
    }
  }

  &.multiRow {
    .videoListInner {
      grid-template-columns: 1fr;
    }
  }
}

.fullVideoList {
  grid-template-rows: min-content min-content min-content 1fr min-content;
  padding-bottom: 1rem;

  .searchingForLabel {
    font-size: 2rem;
    font-weight: 600;
    justify-content: center;
    align-items: center;
  }
}

.videoFilter {
  display: flex;
  position: sticky;
  top: 0;
  justify-content: space-between;
  align-content: center;
  padding: 0.5rem 1rem;
  column-gap: 1rem;
  z-index: 1;
  background-color: var(--BackGround2);

  @media screen and (max-width: 1280px) {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    max-width: 100vw;
    max-width: 100dvw;
  }
  @media screen and (max-height: 300px) {
    display: grid;
    row-gap: 1dvw;
  }
  .filterContainer {
    grid-auto-flow: column;
    grid-template-columns: max-content 1fr;
    column-gap: 0.5rem;
    justify-content: flex-start;
    align-content: center;
    max-width: 100vw;
    max-width: 100dvw;

    @media screen and (max-width: 1280px) {
      grid-template-columns: 8rem 1fr;
      row-gap: 1rem;
    }
    .filterTitle {
      font-size: 1.2rem;
      color: var(--FontColor3);
      align-items: center;

      @media screen and (max-width: 1280px) {
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
    .filterSelect {
      display: flex;
      flex-direction: row;
      flex-flow: wrap;
      gap: 0.5rem;
      .filterOption {
        font-size: 1.2em;
        height: 1.8rem;
        align-items: center;
        padding: 0 0.2rem;
        border-radius: 0.2rem;
        cursor: pointer;
        transition: 0.2s ease;

        &:hover {
          @media (pointer: fine) {
            background-color: var(--Color1);
          }
        }

        @media screen and (max-width: 1280px) {
          font-size: 1.3rem;
          letter-spacing: 0.2rem;
        }
      }
      &.multi {
        .filterOption {
          &.selected {
            background-color: var(--Color1);
          }
        }
      }
    }
  }
}

.videoLists {
  &.tabbed {
    .videoListTitleSection {
      padding: 0 0.2rem 0.2rem 0.2rem;
    }

    .listSelectorHeader {
      display: flex;
      gap: 1rem;
      padding: 0 0.5rem;

      .listSelector {
        font-size: 1.5rem;
        font-weight: 400;
        cursor: pointer;

        .underline {
          height: 0.2rem;
          min-height: 0.2rem;
          width: calc(100% - 0.05rem);
          align-self: flex-start;
        }

        &.active {
          .underline {
            background-color: var(--FontColor3);
          }
        }
      }
    }
  }
}
