body {
  --Color1: #ae1f24;
  --Color2: #000000;
  --Color3: #ffffff;
  --FontColor1: #ffffff;
  --FontColor2: #000000;
  --FontColor3: #ae1f24;
  --FontColor4: #ffd700;
  --FontColor5: #ee1f24;
  --BackGround1: #2b2b30;
  --BackGround2: #000000;
  --BackGroundGradient: linear-gradient(to bottom, #17171c, #000000);
  --siteFontFamily1: "Bebas", "League Gothic", "Roboto", sans-serif;
  --siteFontFamily2: "Calibri", "Roboto", sans-serif;
}
