/*
Original @ https://github.com/theonion/videojs-vast-plugin (commit bf6ce85fa763299739f6a7c801b5be4b90b3b363)
*/

.vast-skip-button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  bottom: 40px;
  cursor: pointer;
  padding: 10px;
  pointer-events: auto;
  position: absolute;
  right: 0;
  z-index: 5;
  width: auto;
  height: auto;
  font-size: 2rem;
  border: #aaa 1px solid;
  border-right: 0;
}

.vast-skip-button.enabled {
  cursor: pointer;
  color: #fff;
}

.vast-skip-button.enabled:hover {
  cursor: pointer;
  background: #333;
}

.vast-blocker {
  display: block;
  position: absolute;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
