.admin {
  grid-template-columns: 15rem 1fr;
  grid-template-rows: 1fr;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  background-color: var(--Color2);
}

.adminSidebar {
  border-right: solid 0.1rem var(--Color1);
  background-color: var(--Color2);
  grid-auto-flow: row;
  height: 100%;
  grid-auto-rows: min-content;

  .sidebarOption {
    padding: 1rem;
    border-bottom: solid 0.1rem var(--BackGround2);
    font-size: 2rem;
    justify-content: center;
    align-content: center;
    cursor: pointer;
    user-select: none;

    &.title {
      background-color: var(--BackGround2);
      cursor: default;
    }

    &:hover {
      @media (pointer: fine) {
        background-color: var(--Color1);
        opacity: 0.6;
        &.title {
          background-color: var(--BackGround2);
          opacity: 1;
          cursor: default;
        }
      }
    }

    &.selected {
      background-color: var(--Color1);
    }

    &.refreshData {
      position: absolute;
      bottom: 4rem;
      border-bottom: unset;
      border-top: solid 0.1rem var(--BackGround2);
      align-self: flex-end;
      width: 15rem;
    }
  }
}

.adminList {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;

  .searchItems {
    padding: 0.3rem;
    display: grid;
    grid-template-columns: min-content min-content max-content 1fr;
    column-gap: 0.5rem;

    svg {
      color: var(--Color1);
      font-size: 1.5rem;
    }
    input {
      max-width: 15rem;
    }

    .buttonsContainer {
      display: flex;
      flex-direction: column;
      column-gap: 0.5rem;
      .funkerButton {
        width: max-content;
      }
    }

    .searchIncludeAll {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 0.5rem;
      align-items: center;
      padding-right: 1rem;

      input {
        display: inline-block;
      }
    }
  }
  .funkerTable {
    height: 100%;
    background-color: var(--BackGround2);

    &.hasPaging {
      padding-bottom: 3rem;
    }

    .funkerTableItem {
      cursor: pointer;
    }
  }
}
