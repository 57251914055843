.home {
  grid-template-rows: min-content min-content;

  .homeLists {
    padding-top: 1rem;
  }

  .hideList {
    display: none;
  }
}
